/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Call } from '@libs/lila-call';
import { Auth } from '@libs/lila-auth';
import MainStore, { state } from '@store/main.store';
import log from 'loglevel';
import {
  Editor, ListOfModels, History, Content, EditorBase, CompatibleGenericDataType
} from '@lilaquadrat/studio/lib/interfaces';
import GenericStore from '@interfaces/GenericStore.interface';
import { ErrorObject } from 'ajv';
import StudioSDK, { SDKResponse } from '@libs/StudioSDK';
import isObjectId from '@mixins/isObjectId';
import { DesignCustomModule, DesignEvent } from '@lilaquadrat/interfaces';
import AppEditorDataState from '../interfaces/data-state.interface';

const AppEditorDataStore: GenericStore<AppEditorDataState> = {

  sdkOptions() {

    return {
      authToken: state.authToken,
      customEndpoints: MainStore.getters.endpointsSDK,
      ...MainStore.getters.companyProject,
    };

  },

  namespaced: true,
  strict: true,

  state: {
    settings: {},
    crumbs: [],
    cache: null,
    active: false,
    type: null,
    state: null,
    errors: null,
    lastSaved: null,
    history: {},
    historySingle: null,
    layout: {} as undefined,
    viewport: null,
    showHistoryCurrent: false,
    events: [],
    customModules: [],
    content: {},
    untouched: {},
    base: {
      app: null,
      server: null,
    },
  },

  mutations: {

    add(appEditorState, data: { title: string, component: any, key?: string }) {

      appEditorState.crumbs.push(data);

    },

    setCache(appEditorState, cacheString: string) {

      appEditorState.cache = cacheString;

    },

    remove(appEditorState, index: number) {

      const element = appEditorState.crumbs[index + 1];

      if (element) {

        element.unset();
        delete element.unset;

      }

      appEditorState.crumbs = appEditorState.crumbs.slice(0, index + 1);

    },

    clearCrumbs(appEditorState) {

      appEditorState.crumbs[0]?.unset();
      appEditorState.crumbs = [];

    },


    active(appEditorState, data) {

      appEditorState.active = data;

    },

    type(appEditorState, type: string) {

      appEditorState.type = type;

    },

    state(appEditorState, status: string) {

      appEditorState.state = status;

    },

    content(appEditorState, data: Partial<Content>) {

      appEditorState.content = data;

    },

    untouched(appEditorState, data: Partial<Content>) {

      appEditorState.untouched = data;

    },

    errors(appEditorState, errors: ErrorObject[]) {

      appEditorState.errors = errors;

    },

    lastSaved(appEditorState, date: Date) {

      appEditorState.lastSaved = date;

    },

    viewport(appEditorState, viewport) {

      appEditorState.viewport = viewport;

    },

    layout(appEditorState, layout) {

      appEditorState.layout = layout;

    },

    showHistoryCurrent(appEditorState, show) {

      appEditorState.showHistoryCurrent = show;

    },

    historySingle(appEditorState, history: History) {

      appEditorState.historySingle = history;

    },

    history(appEditorState, history: ListOfModels<History>) {

      appEditorState.history = history;

    },

    base(appEditorState, data: { type: 'app' | 'server', data: EditorBase }) {

      appEditorState.base[data.type] = data.data;

    },

    contentGenericDataIndex(appEditorState, data: { index: string[], type: CompatibleGenericDataType }) {
  
      if(appEditorState.content.genericData) appEditorState.content.genericData[data.type] = data.index?.filter((single) => single);

    },

    customModules(appEditorState, customModules: DesignCustomModule[]) {

      appEditorState.customModules = customModules;

    },

    events(appEditorState, events: DesignEvent[]) {

      appEditorState.events = events;

    },

  },

  actions: {

    editGenericDataIndex(store, params: { add?: string[], remove?: string[], type: CompatibleGenericDataType }) {
  
      // Retrieve generic data from the store's content state. If not present, default to an empty object.
      const baseData = store.state.content.genericData ?? {};
      // Initialize tempIndex with data from the store if available, otherwise default to an empty array.
      let tempIndex: string[] = [...(baseData[params.type] ? store.state.content.genericData[params.type] : [])];

      // If there are items to add from the params, merge them into tempIndex ensuring uniqueness.
      if (params.add) {

          tempIndex = [...new Set([...tempIndex, ...params.add])];

      }

      // If there are items to remove from the params, filter them out from tempIndex.
      if (params.remove) {

          tempIndex = tempIndex.filter((single) => !params.remove.includes(single));

      }

      // Commit the updated tempIndex to the store with the associated type.
      return store.commit('contentGenericDataIndex', { index: tempIndex, type: params.type });


    },

    getContent(store, params: any) {

      const sdk = new StudioSDK('editor-app', AppEditorDataStore.sdkOptions());

      return sdk.editor.getById(params.id)
        .then((data) => {

          // store.commit('childDataIndex', data.data.childData?.index);
          // store.commit('childDataCache', data.data.childData?.data);
          store.commit('untouched', data.data);
          store.commit('content', data.data);

          return data.data;

        })
        .catch((e) => {

          log.error(e);

        });


    },


    getBase(store, params: { company: string, project: string, type: EditorBase['type'] }) {

      // const call = new Call<EditorBase>('editor', MainStore.getters.endpoints);
      // const queryArray = ['editor', params.company, params.project, params.type];

      // return call.get('media', `/${queryArray.join('/')}`, undefined, Auth.getAuthHeader())
      //   .then((data) => {

      //     store.commit('base', { type: params.type, data: data.status === 200 ? data.r : false });

      //   });

      const sdk = new StudioSDK('editor-app', AppEditorDataStore.sdkOptions());

      return sdk.renderer.get(params.type)
        .then((data) => this.commit('base', { type: params.type, data: data.status === 200 ? data.data : false }));

    },

    updateBase(store, params: { company: string, project: string, type: EditorBase['type'] }) {

      // const call = new Call<EditorBase>('editor', MainStore.getters.endpoints);
      // const queryArray = ['editor', params.company, params.project, 'update', params.type];

      // return call.get('media', `/${queryArray.join('/')}`, undefined, Auth.getAuthHeader());

      const sdk = new StudioSDK('editor-app', AppEditorDataStore.sdkOptions());

      return sdk.renderer.update(params.type);

    },

    resetBase(store, params: { company: string, project: string, type: EditorBase['type'] }) {

      // const call = new Call<EditorBase>('editor', MainStore.getters.endpoints);
      // const queryArray = ['editor', params.company, params.project, 'reset', params.type];

      // return call.post('media', `/${queryArray.join('/')}`, undefined, Auth.getAuthHeader());

      const sdk = new StudioSDK('editor-app', AppEditorDataStore.sdkOptions());

      return sdk.renderer.reset(params.type);

    },

    checkBaseUpdate(store, params: { company: string, project: string, type: EditorBase['type'] }) {

      // const call = new Call<EditorBase>('editor', MainStore.getters.endpoints);
      // const queryArray = ['editor', params.company, params.project, 'updateAvailable', params.type];

      // return call.head('media', `/${queryArray.join('/')}`, Auth.getAuthHeader());

      const sdk = new StudioSDK('editor-app', AppEditorDataStore.sdkOptions());

      return sdk.renderer.checkUpdate(params.type);

    },

    getEditorBase(store, params: any) {

      const call = new Call<Editor | History>('editor', MainStore.getters.endpoints);
      const queryArray = [params.type === 'history' ? 'history' : 'editor', params.company, params.project, 'internal', params.id];

      return call.get('api', `/${queryArray.join('/')}`, undefined, Auth.getAuthHeader())
        .then((data) => data.r)
        .catch((e) => {

          log.error(e);

        });

    },

    getEditorHistory(store, params: any) {

      const call = new Call<Editor>('editor', MainStore.getters.endpoints);
      const queryArray = ['history', params.company, params.project, 'internal', params.history];

      return call.get('api', `/${queryArray.join('/')}`, undefined, Auth.getAuthHeader())
        .then((data) => store.commit('history', data.r))
        .catch((e) => {

          console.error(e);

        });

    },

    getPartial(store, params: any) {

      const call = new Call<Editor>('editor', MainStore.getters.endpoints);
      const queryArray = ['editor', params.company, params.project, 'internal', params.id];

      return call.get('api', `/${queryArray.join('/')}`, undefined, Auth.getAuthHeader())
        .then((data) => data.r);

    },

    async layout(store, params: { id: string }) {

      const sdk = new StudioSDK('editor-app', AppEditorDataStore.sdkOptions());
      let layout: SDKResponse<Editor>;

      try {
        
        /**
         * check for legacy reasons if the id is a mongodb string
         */
        if(!isObjectId(params.id)) {
  
          layout = await sdk.editor.getById(params.id);
  
        } else {

          layout = await sdk.editor.getByInternalId(params.id);
        
        }
  
        this.commit('AppEditorData/layout', layout.data);

      } catch (error) {
        
        console.error(error);
        this.commit('AppEditorData/layout');
        return false;

      }

      return true;


    },

    toggleModule(store, params: { position: string, index: string | boolean }) {

      this.commit('AppEditorData/active', params);
      this.commit('AppEditorData/clearCrumbs');

    },

    create(store, data) {

      const call = new Call<void>('editor', MainStore.getters.endpoints);

      return call.post('api', `/editor/${data.company}/${data.project}`, { ...data.content }, Auth.getAuthHeader());

    },

    update(store, data) {

      const call = new Call<void>('editor', MainStore.getters.endpoints);

      return call.put('api', `/editor/${data.company}/${data.project}/${data.id}`, { ...data.content }, Auth.getAuthHeader());

    },

    delete(store, data) {

      const call = new Call<void>('editor', MainStore.getters.endpoints);

      return call.delete('api', `/editor/${data.company}/${data.project}/${data.id}`, Auth.getAuthHeader());

    },

  },

};

export default AppEditorDataStore;
