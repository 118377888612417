export default {
  'app-lists': 'Listen',
  'app-lists-add-title': 'Liste erstellen',
  'app-lists-edit-title': 'Liste bearbeiten',
  'app-lists-remove-button': 'Liste entfernen',
  'app-lists-base': 'Allgemeines',
  'app-lists-name': 'Name',
  'app-lists-participants': 'Kunden',
  'app-lists-description': 'Beschreibung',
  'app-lists-title-entries': 'Einträge',
  'app-lists-state': 'Status',
  'app-lists-title-state': 'Status',
  'app-lists-state-waiting': 'Nicht veröffentlicht',
  'app-lists-state-open': 'Offen',
  'app-lists-state-closed': 'Geschlossen',
  'app-lists-mode': 'Modus',
  'app-lists-title-mode': 'Modus',
  'app-lists-mode-contact': 'Kontakt',
  'app-lists-mode-contact-description': 'Ein klassisches Kontaktformular mit integrierter Spam-Erkennung zur sicheren Kommunikation.',
  'app-lists-mode-content': 'Inhalte',
  'app-lists-mode-content-description': 'Ermöglicht Kunden den Zugang zu exklusiven Inhalten nach ihrer Anmeldung zur Liste.',
  'app-lists-mode-reservation': 'Reservierung',
  'app-lists-mode-reservation-description': 'Dient dazu, Plätze für Veranstaltungen zu sichern und Reservierungen effizient zu verwalten.',
  'app-lists-mode-shopping': 'Shopping',
  'app-lists-mode-shopping-description': 'Unterstützt den Kauf mehrerer Produkte und verwaltet die Bestellungen der Kunden.',
  'app-lists-categories-no-title': 'Kein Name',
  'app-lists-categories-add': 'Neue Kategorie hinzufügen',
  'app-lists-create-button': 'Liste erstellen',
  'app-lists-edit-button': 'Liste bearbeiten',
  'app-lists-categories-elements-parent': 'Kategorie Element %s:',
  'app-lists-categories-name': 'Name',
  'app-lists-categories-id': 'ID',
  'app-lists-categories-deactivated': 'Buchung deaktivieren',
  'app-lists-categories-amount': 'Maximal verfübare Einheiten',
  'app-lists-categories-currency': 'Währung',
  'app-lists-price-amount': 'Kosten',
  'app-lists-price-tax': 'Steuersatz in %',
  'app-lists-price-currency': 'Währung',
  'app-lists-payment': 'Zahlungspflichtig',
  'app-lists-payment-free': 'nein',
  'app-lists-payment-optional': 'freiwillig',
  'app-lists-payment-required': 'ja',
  'app-lists-title-category': 'Kategorie',
  'app-lists-cateogries': 'Kategorien',
  'app-lists-agreements': 'Vereinbarungen',
  'app-lists-agreements-description': 'Durch Vereinbarungen können Sie festlegen, welche Bestätigungen der Nutzer, optional oder verpflichtend, vor der Eintragung in die Liste vornehmen muss. z.b. Widerrufsbelehrungen',
  'app-lists-agreements-text': 'Beschreibung',
  'app-lists-agreement-selected-id': 'Ausgewählter Datensatz:',
  'app-lists-agreements-no-matches': 'Keine passenden Datensätze gefunden',
  'app-lists-agreements-search': 'Datensatz',
  'app-lists-agreements-required': 'Muss bestätigt werden',
  'app-lists-agreements-add': 'Vereinbarung hinzufügen',
  'app-lists-agreements-no-title': 'Kein Datensatz ausgewählt',
  'app-lists-agreements-contentId': 'Datensatz',
  'app-lists-agreements-elements-parent': 'Vereinbarungen Element %s:',
  'app-lists-agreements-contentId-missing': 'Es muss ein Datensatz ausgewählt werden',
  'app-lists-agreements-dsgvo-description': 'Kunden müssen der Datenverarbeitung zustimmen um die Listen nutzen können. Unser vordefinierten Datensatz beinhaltet alle notwendigen Information und muss zwingend sichtbar sein und bestätigt werden.',
  'app-lists-participants-unique': 'Kunden können sich nur einmalig eintragen',
  'app-lists-participants-addressRequired': 'Vollständige Adresse ist erforderlich',
  'app-lists-participants-addressRequired-description': 'Kunden können sich nur eintragen wenn sie ihre vollständige Adresse angegeben haben.',
  'app-lists-participants-max': 'Anzahl der verfügbaren Plätze',
  'app-lists-categories-description': 'Gibt den Kunden die Möglichkeit aus verschiedenen Varianten zu wählen. z.B. um normale und VIP Kunden zu klassifizieren oder bei Kontaktanfragen den Grund auszuwählen.',
  'app-lists-categories-description-title': 'Beschreibung',
  'app-lists-participant-created': 'Eintrag erstellt',
  'app-lists-participant-state': 'Status',
  'app-lists-participant-amount': 'Betrag',
  'app-lists-participant-state-enlisted': 'eingetragen',
  'app-lists-participant-state-confirmed': 'bestätigt',
  'app-lists-participant-state-reserved': 'reserviert',
  'app-lists-participant-state-waiting': 'warte auf aktion',
  'app-lists-participant-state-blocked': 'geblockt',
  'app-lists-participant-state-finalized': 'abgeschlossen',
  'app-lists-participant-state-unchecked': 'warte auf bewertung',
  'app-lists-participant-state-notuseful': 'spam',
  'app-lists-participant-state-paid': 'bezahlt',
  'app-lists-participant-no-note': 'Hinweis hinzufügen',
  'app-lists-participant-link': 'anzeigen',
  'app-lists-participant-customer': 'Kunde',
  'app-lists-participant-edit-button': 'speichern',
  'app-lists-participants-title-created': 'Erstelldatum',
  'app-lists-participants-filled-percent': '%s% der insgesamten Plätze (%s) sind belegt',
  'app-lists-participant-date': 'Datum',
  'app-lists-participant-note': 'Interner Hinweis',
  'app-lists-participant-message': 'Nachricht',
  'app-lists-participant-source': 'Quelle',
  'app-lists-participant-category': 'Kategorie',
  'app-lists-participant-accepted-agreements': 'Akzeptierte Vereinbarungen',
  'app-lists-participants-total-paid': '%s Gesamt',
  'app-lists-participant-paid': 'Zahlung',
  'app-lists-participant-items': 'Items',
  'app-lists-select-confirm': 'Auswahl bestätigen (%s)',
  'app-lists-select-cancel': 'abbrechen',
  'app-lists-select-data-title': 'Liste auswählen',
  'app-lists-select-data-title-multiple': 'Listen auswählen',
  'app-lists-select-button-title': 'Liste auswählen',
  'app-lists-select-button-title-multiple': 'Listen auswählen',
  'app-lists-select-open': 'Liste öffnen',
  'app-lists-select-selected-overview': '%s ausgewählt',
  'app-lists-select-no-select': 'Keine Liste ausgewahlt',
  'app-lists-content': 'Inhalte',
  'app-lists-emails': 'E-Mails',
  'app-lists-content-description': 'Im Laufe ihres Bestehens durchläuft eine Liste verschiedene Phasen. Für jede Phase lässt sich ein spezifischer Datensatz wählen, um den Kunden die passenden Inhalte anzuzeigen.',
  'app-lists-content-pre': 'Pre',
  'app-lists-content-pre-description': 'Inhalt wird angezeigt bevor man sich in die Liste eintragen kann. Zum Beispiel als Teaser für das bevorstehende Event.',
  'app-lists-content-open': 'Offen für Interaktion',
  'app-lists-content-open-description': 'Liste ist aktiv, Kunden können sich eintragen. Der Datensatz sollte ein Kontaktmodule mit dieser Liste beinhalten.',
  'app-lists-content-waiting': 'Warte auf Bestätigung',
  'app-lists-content-waiting-description': 'Kunde hat sich bereits eingetragen, wurde aber noch nicht bestätigt. Hier sollten Informationen angezeigt werden, die dem Kunden seinen Status erklären.',
  'app-lists-content-active': 'Aktiv',
  'app-lists-content-active-description': 'Kunde ist eingetragen und bestätigt. Inhalte die nur für eingetragene Kunden sichtbar sein sollten, können hier angezeigt werden', 
  'app-lists-content-post': 'Post',
  'app-lists-content-post-description': 'Liste/Event liegt in der Vergangenheit. Inhalte für Teilnehmer die nach der Veranstaltung interessant sind, können hier dargestellt werden.',
  'app-lists-content-paid': 'Bezahlt',
  'app-lists-content-paid-description': 'Kunde hat ein oder mehrere Produkte bezahlt. Dieser Inhalt wieder angezeigt wenn nach dem Checkout auf die Seite weitergeleitet wird.',
  'app-lists-emails-description': 'Bei den nachfolgenden Status können E-Mails an den Kunden gesendet werden',
  'app-lists-emails-waiting': 'Warte auf Bestätigung',
  'app-lists-emails-waiting-description': 'Kunde hat sich bereits eingetragen, wurde aber noch nicht bestätigt. Diese E-Mail sollte dem Kunden erklären was die nächsten Schritte sind.',
  'app-lists-emails-active': 'Aktiv',
  'app-lists-emails-active-description': 'Kunde ist eingetragen und bestätigt. Mit dieser E-Mail soll der Kunde darauf hingewiesen werden, dass er nun aktiv ist.',
  'app-lists-emails-post': 'Post',
  'app-lists-emails-post-description': 'Liste/Event liegt in der Vergangenheit. Inhalte für Teilnehmer die nach der Veranstaltung interessant sind, können hier versendet werden.',
  'app-lists-emails-paid': 'Bezahlt',
  'app-lists-emails-paid-description': 'Kunde hat etwas gekauft und bekommt den gewählten Datensatz als Bestellbestätigung'
};
