import LoadElements from '@mixins/loadElements';
import InstallableApp from '@interfaces/InstallableApp.interface';
import routes from './routes';
import translations from './translations/translations';

import './models/customer-company.model';
import './models/customer-person.model';

// LoadElements.appComponents(require.context('./partials', true, /[a-z]\w+\.partial\.(ts|vue)$/, 'lazy'), 'partials', 'customers');
// LoadElements.appComponents(require.context('./modules', true, /[a-z-]\w+\.module\.(editor\.)?vue$/, 'lazy'), 'modules', 'customers');
LoadElements.extensions(require.context('./extensions', true, /[a-z-]\w+\.extension\.(ts|vue)$/, 'lazy'), 'customers');

const app: InstallableApp = {
  name: 'customers',
  publisher: 'lilaquadrat',
  translations,
  routes,
  store: 'AppCustomers',
};

export default app;
