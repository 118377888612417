import vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import inview from '@libs/lila-inview';
import Dom from '@libs/lila-dom';
import { Auth } from '@libs/lila-auth';
import { Route } from 'vue-router';
import { Dictionary } from 'vue-router/types/router';
import MainStoreState from '@store/mainStoreState.interface';
import { Store } from 'vuex';
import uuid from '@mixins/uid';
import SettingsClass, { Settings } from '@libs/lila-settings';
import equal from 'fast-deep-equal';
import cleanObject from '@mixins/cleanObject';
import hardCopy from '@mixins/hardCopy';
import moment from '@mixins/moment';
import $currency from '@mixins/currency';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
  'beforeRouteLeave',
  'beforeUnmount',
  'unmounted',
  'startLoad',
]);

/**
 *  base class for extensions
 *
 * @class ExtComponent
 * @extends {vue}
 */

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
@Component({
  inheritAttrs: false,
})
abstract class ExtComponent extends vue {

  public state: string = '';

  public view: string = '';

  public fullscreen: boolean = false;

  public DOM: Dom;

  public triggerScroll: boolean = true;

  public child: string = '';

  public componentName: string | string[] = '';

  public animation: string = '';

  /**
   * disables the animation for the next routing
  */
  public animationDisabled: boolean = false;

  public sidescreen: boolean = false;

  public uuid: string;

  /**
   * when this variable is changed the NavigationStore is updated and the top navigation will show the value.
   * through routeleave will this component unset the variable in the navigation store
   * 
   */
  public activeElement: string = null;

  /**
   * if this element changed the activeElement if will reset the activeElement in the NavigationStore on routeLeave
   */
  public activeElementChanged: boolean = false;

  @Prop({ type: Array, default: () => [] }) variant: string[];

  @Prop(String) parentKey?: string;

  @Prop(String) id?: string;

  // eslint-disable-next-line no-unused-vars
  leaveGuard: (to: Route, from: Route, next: () => void, component: this) => void;

  actions: {}[];

  @Watch('activeElement')
  activeElementWatcher() {

    this.$store.commit('Navigation/activeElement', this.activeElement);
    this.activeElementChanged = true;

  }

  constructor() {

    super();
    this.DOM = new Dom(this.$store);
    this.uuid = uuid();

  }

  /**
   * add a eventlistener to this element
   * calls the [[Inview.check]] function
   *
   * @memberof ExtComponent
   */
  checkInview() {

    window.addEventListener('scrolled', () => {

      inview.check(this);

    });

  }

  /**
   * editor specific logic
   * TODO needs to be removed
   *
   * @param {string} child
   * @returns
   * @memberof ExtComponent
   */
  setChild(child: string) {

    if (this.child === child) {

      this.child = '';
      return;

    }

    this.$store.commit('AppEditorData/add', { title: child, unset: this.unsetChild(this), parent: this.uuid });
    this.child = child;

  }

  /**
   * editor specific logic
   * TODO needs to be removed
   *
   * @param {*} ref
   * @returns
   * @memberof ExtComponent
   */
  // eslint-disable-next-line class-methods-use-this
  unsetChild(ref: any) {

    return () => {

      ref.child = '';

    };

  }

  // eslint-disable-next-line class-methods-use-this
  $date(value: Date, format?: string, fromNow?: boolean) {
    
    return moment(value, format, fromNow);
    
  }
  
  // eslint-disable-next-line class-methods-use-this
  $formatBytes(bytes: number, decimals?: number) {

    if (!bytes) return '0 Bytes';

    const k = 1000;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
  
    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;

  }

  // // eslint-disable-next-line class-methods-use-this
  // $translate(text: string, values?: (string | number)[]) {

  //   return translate.translate(text, undefined, undefined, values);

  // }

  // eslint-disable-next-line class-methods-use-this
  $categories(value: string[]) {

    const returnValue = [];

    value?.forEach((single) => {

      if (!single.match('^category:')) return;

      returnValue.push(single.slice(9));

    });

    return returnValue.join(', ');

  }

  // eslint-disable-next-line class-methods-use-this
  $currency(amount: string | number) {

    return $currency(amount);

  }


  // eslint-disable-next-line class-methods-use-this
  attachScrollCheck(elements: NodeListOf<Element> | Element[]) {

    elements.forEach((element) => {

      inview.addScrollListener(element);

    });

  }

  scope(scope: string[]): boolean {

    if (!scope) return true;
    return Auth.checkScope(this.$store.state.user, scope, this.$store.state.Company.company, this.$store.state.Project.project);

  }

  // eslint-disable-next-line class-methods-use-this
  beforeRouteUpdate(to, from, next) {

    if (to.meta.rememberSettings && (!equal(to.query, {}) || to.params.site)) {

      const cleanedQuery = hardCopy(to.query);

      cleanObject(cleanedQuery);

      if (!equal(cleanedQuery, {}) || to.params.site) {

        SettingsClass.update(Settings.getKeyAppRoute(to), { query: cleanedQuery, site: to.params.site });

      } else {

        SettingsClass.remove(Settings.getKeyAppRoute(to));

      }

    }

    next();

  }

  /**
   * sets the animation to fadeOut
   *
   * @param {Route} to
   * @param {Route} from
   * @param {() => {}} next
   * @memberof ExtComponent
   */
  beforeRouteLeave(to: Route, from: Route, next: (stop) => void): void {

    console.log(this.leaveGuard, typeof this.leaveGuard, to, from);

    if (typeof this.leaveGuard === 'function' && !to.params.settingsRedirected) {

      this.leaveGuard(to, from, (navigate: boolean = true) => {

        if (navigate) {

          this.routeLeave(next);

        } else {

          next(false);

        }

      }, this);

    } else {

      this.routeLeave(next);

    }

  }

  routeLeave(next: (stop?) => void): void {

    if(this.activeElementChanged) {

      this.$store.commit('Navigation/activeElement');
    
    }

    if (this.animationDisabled) {

      next();
      return;

    }

    this.animation = 'out';
    next();

  }

  /**
   * checks if the targetComponentname is euqal this componentname or the targetComponentname is in this componentname array
   * sidescreen is always fade in
   * if so, set animation to fade in
   *
   * @memberof ExtComponent
   */
  beforeMount() {

    if(this.actions) this.extendActions();

    if (this.animationDisabled) {

      this.animationDisabled = false;
      return;

    }

    if (Array.isArray(this.componentName)) {

      if (this.componentName.includes(this.$store.state.Navigation.targetComponent) || this.sidescreen) this.fadeIn();

    } else if ((this.componentName && this.$store.state.Navigation.targetComponent === this.componentName) || this.sidescreen) {

      this.fadeIn();

    }

  }

  @Watch('$route')
  routeChange() {

    this.animationDisabled = false;
    this.animation = '';

  }

  get animationTarget() {

    return this.$store.state.Navigation.targetComponent;

  }

  get loadingState() {

    return this.$store.state.Navigation.status;

  }

  /**
   * if animation is 'out' - we are leaving this component - set the class for fadeout animation
   * if animation is 'in' - we are entering this component - and loadingState is resolving, set class for fadein
   *
   * @readonly
   * @memberof ExtComponent
   */
  get navigationAnimation() {

    if (this.$store.state.Navigation.contentUpdate) return {};

    return {
      started: this.animation === 'out',
      resolving: this.animation === 'in' && ['resolving', 'loading'].includes(this.loadingState),
    };

  }

  get contentAnimation() {

    if (!this.$store.state.Navigation.contentUpdate) return { contentUpdate: true };

    return {
      contentUpdate: true,
      started: this.loadingState !== 'done',
      resolving: this.loadingState === 'resolving',
    };

  }

  fadeOut() {

    this.animation = 'out';

  }

  fadeIn() {

    this.animation = 'in';

  }

  extendActions() {

    const componentName = Array.isArray(this.componentName)
      ? this.componentName[0]
      : this.componentName;

    if(this.$store.state.actionsComponents.includes(componentName)) return;

    this.$store.dispatch('extendActions', {source: this.componentName, actions: this.actions});

  }

  /** @deprecated */
  asyncData?(
    params: Dictionary<string>,
    query: Dictionary<string | string[]>,
    store: Store<MainStoreState>,
    to: Route
  ): Promise<unknown>;

  preloadDataPre?(
    params: Dictionary<string>,
    query: Dictionary<string | string[]>,
    store: Store<MainStoreState>,
    to: Route
  ): Promise<unknown>;

  preloadDataPost?(
    preloadedData: unknown,
    params: Dictionary<string>,
    query: Dictionary<string | string[]>,
    store: Store<MainStoreState>,
    to: Route
  ): Promise<unknown>;

  preloadError?(error: Error, store: Store<MainStoreState>): void;


}

export {
  Component,
  ExtComponent,
  Prop,
  Watch,
  inview,
  vue,
};
