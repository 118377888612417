import GenericStore from '@interfaces/GenericStore.interface';
import MainStore, { state } from '@store/main.store';
import { DataObject, ObjectIdString, Payment, PaymentProvider } from '@lilaquadrat/interfaces';
import StudioSDK from '@libs/StudioSDK';
import BaseCallData from '@interfaces/BaseCallData.interface';
import AppPaymentMainState from '../interfaces/main-state.interface';

const AppPaymentStore: GenericStore<AppPaymentMainState> = {
  namespaced: true,
  strict: true,

  sdkOptions() {

    return {
      authToken: state.authToken,
      customEndpoints: MainStore.getters.endpointsSDK,
      ...MainStore.getters.companyProject,
    };

  },


  state: {

    data: {},

    providers: {},

    search: {
      tags: [],
      search: null,
      type: null,
      customer: null,
      sort: '_id',
      order: '1',
    },

    single: null,
    singleProvider: null,

  },

  mutations: {

    data(store, data: DataObject<Payment>) {

      store.data = data;

    },

    single(store, data: Payment) {

      store.single = data;

    },

    singleProvider(store, data: PaymentProvider) {
    
        store.singleProvider = data;
  
    },

    updateSearch(appPaymentState, update: { data: any, type: string }) {

      console.log(update, update.data.length, !update.data.length, appPaymentState.search);

      if (!update.data.length) {

        appPaymentState.search[update.type] = null;
        return;

      }

      console.log(update, appPaymentState.search);

      appPaymentState.search[update.type] = update.data;

    },

    setSearch(appPaymentState, search: { tags?: string[], search?: string, type?: string, customer?: string }) {

      appPaymentState.search = search;

    },


    providers(store, data: DataObject<PaymentProvider>) {

      store.providers = data;

    },

  },

  actions: {
    // sdkListExample(store, data: {data: Test, id: string}) {

    //   const sdk = new StudioSDK('test-app', AppTestStore.sdkOptions());

    //   return sdk.test.list(data.id, data.data);

    // },

    get(store, data: { settings: BaseCallData, query?: Record<string, string> }) {

      const sdk = new StudioSDK('payments', AppPaymentStore.sdkOptions());

      console.log(data.query);

      return sdk.payments.list(+data.settings.site, data.query);

    },

    getTests(store, data: { settings: BaseCallData, query?: Record<string, string> }) {

      const sdk = new StudioSDK('payments', AppPaymentStore.sdkOptions());

      console.log(data.query);

      return sdk.payments.tests.list(+data.settings.site, data.query);

    },

    single(store, data: { settings: BaseCallData, _id?: string }) {

      const sdk = new StudioSDK('payments', AppPaymentStore.sdkOptions());

      return sdk.payments.single(data._id);

    },

    singleProvider(store, data: { settings: BaseCallData, _id?: string }) {

      const sdk = new StudioSDK('payments', AppPaymentStore.sdkOptions());

      return sdk.payments.providers.single(data._id);

    },

    updateProvider(store, data: { data: PaymentProvider, id: string }) {

      const sdk = new StudioSDK('payments', AppPaymentStore.sdkOptions());

      return sdk.payments.providers.update(data.id, data.data);

    },

    getProviders(store, data: { settings: BaseCallData, query?: any }) {

      const sdk = new StudioSDK('publish-app', AppPaymentStore.sdkOptions());

      return sdk.payments.providers.list(+data.settings.site);

    },

    addProvider(store, data: PaymentProvider) {

      const sdk = new StudioSDK('publish-app', AppPaymentStore.sdkOptions());

      return sdk.payments.providers.add(data);

    },

    removeProvider(store, id: ObjectIdString) {

      const sdk = new StudioSDK('publish-app', AppPaymentStore.sdkOptions());

      return sdk.payments.providers.remove(id);

    },

    /**
     * This action will be called, when the app will be activated
     */
    activate(store, params) {

      return Promise.resolve(true);

    },
  },

};

export default AppPaymentStore;
