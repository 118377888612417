import { UnifiedRouteGuard } from '@libs/lila-auth';

const loadScreen = (screen: string) => () => import(
  /* webpackChunkName: "app-payment" */
  `./screens/${screen}`
).then((m) => m.default);

export default [
  {
    path: '/:company/:project/a/payment/:site?',
    name: 'app-payment-project-home',
    beforeEnter: UnifiedRouteGuard,
    component: loadScreen('home.screen.vue'),
    meta: {
      animationGroup: 'payment',
      app: 'payment',
      parent: true,
      rememberSettings: true,
    },
    children: [
      {
        path: 'single/:id',
        component: loadScreen('single.screen.vue'),
        name: 'app-payment-project-single',
        meta: {
          sidescreen: true,
          defaultRoute: 'app-payment-project-single',
          app: 'payment',
        },
      },
      {
        path: 'providers',
        component: loadScreen('providers-overview.screen.vue'),
        meta: {
          sidescreen: true,
          defaultRoute: 'app-payment-providers-home',
          app: 'publish',
        },
        children: [
          {
            path: 'add',
            component: loadScreen('providers-single.screen.vue'),
            name: 'app-payment-providers-add',
            meta: {
              sidescreen: true,
              defaultRoute: 'app-payment-providers-home',
              app: 'publish',
            },
          },
          {
            path: ':id',
            component: loadScreen('providers-single.screen.vue'),
            name: 'app-payment-providers-edit',
            meta: {
              sidescreen: true,
              defaultRoute: 'app-payment-providers-home',
              app: 'publish',
            },
          },
          {
            path: ':site?',
            component: loadScreen('providers-home.screen.vue'),
            name: 'app-payment-providers-home',
            meta: {
              sidescreen: true,
              defaultRoute: 'app-payment-providers-home',
              app: 'publish',
            },
          },
        ],
      },
      {
        path: 'single/:id',
        component: loadScreen('single.screen.vue'),
        name: 'app-payment-project-single',
        meta: {
          sidescreen: true,
          defaultRoute: 'app-payment-project-single',
          app: 'publish',
        },
      },
    
    ],
  },
  {
    path: '/:company/:project/a/payment/tests/:site?',
    name: 'app-payment-project-tests-home',
    beforeEnter: UnifiedRouteGuard,
    component: loadScreen('home.screen.vue'),
    meta: {
      animationGroup: 'payment',
      app: 'payment',
      parent: true,
      rememberSettings: true,
      settingsKey: 'tests',
    },
    children: [
      {
        path: 'single/:id',
        component: loadScreen('single.screen.vue'),
        name: 'app-payment-project-tests-single',
        meta: {
          sidescreen: true,
          defaultRoute: 'app-payment-project-tests-single',
          app: 'payment',
        },
      },
    ],
  },
];
