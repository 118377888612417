
import Models, { ModelDeclarationExtended, ModelOptions } from '@libs/Models.class';
import Module from '@models/Module.model';

export default interface PlaceholderModule extends Module {
  hint: string
  additionalData: string
}

const declaration: ModelDeclarationExtended<PlaceholderModule, Module> = {
  hint: { type: 'string' },
  additionalData: { type: 'string' },
};
const options: ModelOptions = {
  uuid: true,
  nameAsType: true,
  extends: 'module'
};

Models.register('placeholder-module', declaration, undefined, options);