
import Models, { ModelDeclaration } from '@libs/Models.class';

export default interface Link {
  text: string
  link: string
  event: string
  icon: string
  attributes: string[]
  variant: string[]
  additionalData: string,
}

const declaration: ModelDeclaration<Link> = {
  text: { type: 'string' },
  link: { type: 'string' },
  event: { type: 'string' },
  icon: { type: 'string' },
  additionalData: { type: 'string' },
  attributes: { type: 'array', contains: { type: 'string' } },
  variant: { type: 'array', contains: { type: 'string' } },
};

Models.register('link', declaration);
