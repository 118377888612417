export default {
  'app-customers': 'Kunden',
  'app-customers-type': 'Typ',
  'app-customers-type-company': 'Unternehmen',
  'app-customers-type-person': 'Person',
  'app-customers-type-persons': 'Personen',
  'app-customers-address': 'Adresse',
  'app-customers-tags': 'Tags',
  'app-customers-phone': 'Telefon',
  'app-customers-email': 'E-Mail',
  'app-customers-general': 'Allgemeines',
  'app-customers-personal': 'Persönliches',
  'app-customers-contact': 'Kontakt',
  'app-customers-note': 'Hinweis',
  'app-customers-gender': 'Geschlecht',
  'app-customers-birthday': 'Geburtstag',
  'app-customers-categories': 'Kategorien',
  'app-customers-id': 'ID',
  'app-customers-legalRepresentative': 'Kontaktperson',
  'app-customers-register': 'Handelsregister',
  'app-customers-vat': 'Umsatzsteuerid',
  'app-customers-internet': 'Homepage',
  'app-customers-companyData': 'Firmendaten',
  'app-customers-company-single': 'Übersicht',
  'app-customers-company-edit': 'bearbeiten',
  'app-customers-person-prename': 'Vorname',
  'app-customers-person-lastname': 'Nachname',
  'app-customers-person-gender': 'Geschlecht',
  'app-customers-company-name': 'Firmenname',
  'app-customers-add': 'Kunde hinzufügen',
  'app-customers-edit-button': 'Kunde bearbeiten',
  'app-customers-remove-button': 'Kunde entfernen',
  'app-customers-internal': 'Weiteres',
  'app-customers-similar-entries': 'Es gibt ähnliche Kunden',
  'app-customers-similar-no-entries': 'Es gibt keine ähnlichen Kunden',
  'app-customers-similar-description': 'Um zu verhindern, dass Kunden doppelt angelegt werden, werden dir hier Kunden angezeigt die Ähnlichkeiten besitzen.',
  'app-customers-street': 'Strasse',
  'app-customers-streetNumber': 'Hausnummer',
  'app-customers-addressAddition': 'Adresszusatz',
  'app-customers-email-confirmed': 'bestätigt',
  'app-customers-email-unconfirmed': 'nicht bestätigt',
  'app-customers-select-button-title': 'Kunde auswählen',
  'app-customers-select-data-title': 'Kunde auswählen',
  'app-customers-select-cancel': 'abbrechen',

};

