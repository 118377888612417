import LoadElements from '@mixins/loadElements';
import InstallableApp from '../../interfaces/InstallableApp.interface';
import routes from './routes';
import translations from './translations/translations';

import './models/MenuModule.model';
import './models/v1/MenuModule.model';

import './models/NavigationModule.model';

import './models/BlogIntroModule.model';
import './models/CompareModule.model';
import './models/ContactModule.model';
import './models/CookiesModule.model';
import './models/Data.model';
import './models/DataHtmlSettings.model';
import './models/DataPDF.model';
import './models/EmotionModule.model';
import './models/FactsModule.model';
import './models/FaqModule.model';
import './models/FooterModule.model';
import './models/GalleryModule.model';
import './models/IndexModule.model';
import './models/PartialModule.model';
import './models/PictureAndTextModule.model';
import './models/PicturegroupModule.model';
import './models/PictureModule.model';
import './models/PlaceholderModule.model';
import './models/PricesModule.model';
import './models/QuellcodeModule.model';
import './models/Configuration.model';
import './models/SiteSettings.model';
import './models/TextModule.model';
import './models/TrainingModule.model';
import './models/VideoModule.model';
import './models/TitleDescription.model';
import './models/EditorSettings.model';
import './models/QuoteModule.model';
import './models/EventsListModule.model';
import './models/EventsListElement.model';
import './models/EventModule.model';
import './models/CallToActionModule.model';
import './models/Media.model';
import './models/StoryModule.model';
import './models/StoryElement.model';
import './models/LocationModule.model';
import './models/ShoppingCartModule.model';
import actions from './actions';

LoadElements.appComponents(require.context('./partials', true, /[a-z]\w+\.partial(\.v[1-9])?\.(ts|vue)$/, 'lazy'), 'partials', 'editor');
LoadElements.appComponents(require.context('./modules', true, /[a-z-]\w+\.module(\.v[1-9])?\.(editor\.)?vue$/, 'lazy'), 'modules', 'editor');
LoadElements.extensions(require.context('./extensions', true, /[a-z-]\w+\.extension\.(ts|vue)$/, 'lazy'), 'editor');

const app: InstallableApp = {
  name: 'editor',
  publisher: 'lilaquadrat',
  translations,
  routes,
  store: 'AppEditorMain',
  actions
};

export default app;
