import Models, { ModelDeclaration } from '@libs/Models.class';

export default interface AppPaymentProvidersShopify {
  type: 'shopify'
  label: string
  active: boolean
  validationKey: string
}

const declaration: ModelDeclaration<AppPaymentProvidersShopify> = {
  type: { type: 'string', default: 'shopify' },
  label: { type: 'string' },
  active: { type: 'boolean', default: false },
  validationKey: { type: 'string' },
};

Models.register('app-payment-providers-shopify', declaration);