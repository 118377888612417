import LoadElements from '@mixins/loadElements';
import InstallableApp from '../../interfaces/InstallableApp.interface';
import routes from './routes';
import translations from './translations/translations';

import './models/agreements.model';
import './models/categories.model';
import './models/list.model';
import './models/list-shopping.model';
import './models/participant-state.model';
import './models/participant-note.model';
import './models/participants.model';
import './models/price.model';
import './models/content.model';
import './models/content-shopping.model';
import './models/emails.model';

LoadElements.appComponents(require.context('./partials', true, /[a-z]\w+\.partial\.(editor\.)?(ts|vue)$/, 'lazy'), 'partials', 'lists');
// LoadElements.appComponents(require.context('./modules', true, /[a-z-]\w+\.module\.(editor\.)?vue$/, 'lazy'), 'modules', 'lists');
LoadElements.extensions(require.context('./extensions', true, /[a-z-]\w+\.extension\.(ts|vue)$/, 'lazy'), 'lists');

const app: InstallableApp = {
  name: 'lists',
  publisher: 'lilaquadrat',
  translations,
  routes,
  store: 'AppLists',
};

export default app;
