import Models, { ModelDeclaration } from '@libs/Models.class';
import { ListContent } from '@lilaquadrat/interfaces';


const declaration: ModelDeclaration<ListContent> = {
  pre: { type: 'string' },
  open: { type: 'string' },
  waiting: { type: 'string' },
  active: { type: 'string' },
  post: { type: 'string' },
  paid: { type: 'string' },
};

Models.register('app-lists-content', declaration);
